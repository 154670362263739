import './postponeShow.css'
import React, { Component } from 'react'
import { NavBar,Icon,List,InputItem,ImagePicker,Modal } from 'antd-mobile';
import { NEW_YGK_URL, Axios } from '../../../utils/url';
const Item = List.Item;
export default class PostponeShow extends Component {
    constructor(props) {
        super(props);
        const postponeId = this.props.location.query.postponeId;
        this.state = {
            list:{},
            files:[],
            multiple: true,
            bz:"",
            postponeId: postponeId,
            visible: false
        }
    }

    componentDidMount = () => {
        this.getList();
        this.getimgList();
    }

    //查询当前一卡通详情信息
    async getList() {
      console.log("执行获取数据延期详情");
        const url = NEW_YGK_URL+'postpone/getPostponeInfoByPostponeId';
        var params = new URLSearchParams();
        params.append("postponeId", this.state.postponeId);
        await Axios({
            method: 'post',
            url: url,
            data: params
        }).then((res) => {
            console.log(res.data);
            if (res.data.flag) {
                this.setState({
                    list:res.data.data[0]
                });
            }
        });
    }

    async getimgList() {
      const url = NEW_YGK_URL+'postpone/getAffixList';
      var params = new URLSearchParams();
      console.log(this.state.postponeId);
      params.append("postponeId", this.state.postponeId);
      await Axios({
          method: 'post',
          url: url,
          data: params
      }).then((res) => {
          console.log(res);
          if (res.data.flag) {
              this.setState({
                files: res.data.data
              });
          }
      });
  }

    onChange = (files, type, index) => {
        console.log(files, type, index);
        this.setState({
          files,
        });
      }

      handleChange = (e) => {
        this.setState({bz: e});
      }

      imgonclick=(index,files) =>{
        this.setState({
          previewImage: files[index].url,
          visible: true
      });
      }

      onClose=() =>{
        this.setState({
          visible: false
      });
      }



    render() {
        const { files} = this.state
        return (
            <div style={{ marginTop: ".89rem" }}>
            {/* 导航栏#0F84D6 */}
            <NavBar
              mode="dark"
              icon={<Icon type="left"/>}
             onLeftClick={() => this.props.history.goBack()}
            >门禁延期申请详情</NavBar>
            <form onSubmit={this.handleSubmit}>
             <List renderHeader={() => '一卡通基本信息'} >
             <div className="scfj">申请信息</div>
             <InputItem
              value={this.state.list.APPLY_PERSON_NAME}
              editable
            ><div >姓名</div></InputItem>

            <InputItem
              value={this.state.list.GENDER}
              editable
            ><div >性别</div></InputItem>

            <InputItem
              value={this.state.list.CARD_NO}
              editable
            ><div >一卡通号</div></InputItem>

            <InputItem
              value={this.state.list.HR_NO}
              editable
            ><div >人员编号</div></InputItem>

            <InputItem
              value={this.state.list.ID_CARD_NO}
              editable
            ><div >证件编号</div></InputItem>

            <InputItem
              value={this.state.list.PHONE}
              editable
            ><div >联系电话</div></InputItem>

            <InputItem
              value={this.state.list.NOTE==="undefined"?"":this.state.list.NOTE}
              onChange={this.handleChange}
            ><div >备注</div></InputItem>

            <InputItem
              value={this.state.list.thyj}
              editable
            ><div >意见</div></InputItem>

            <div className="scfj">上传附件(身份证正反面)</div>
            <ImagePicker
              files={files}
              onChange={this.onChange}
              onImageClick={this.imgonclick}
              selectable={false}
              multiple={this.state.multiple}
              capture="camera"
            />
             <Modal
                    visible={ this.state.visible }
                    transparent
                    footer={[{ text: '关闭', onPress: () => { console.log('ok'); this.onClose(); } }]}
                >
                    <div style={{ marginTop:50, transform:this.state.transStyle, display:'flex', alignItems:'center'}}>
                        <img
                            alt="example"
                            style={{width: '100%',height:'100%' }}
                            src={this.state.previewImage}
                        />
                    </div>
                    {/* <div style={{clear:'both'}} /> */}
                </Modal>
             </List>
             </form>
            </div>
        )
    }
}
