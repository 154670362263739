import React from 'react'
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
import './utils/url.js'
import Login from './pages/login'
import Main from "./pages/main"
import Zc from './pages/zc'
import Clcrsq from './pages/clgl/clcrsq'
import ClcrsqUpdate from './pages/clgl/clcrsq/clcrsqUpdate'
import Clbg from './pages/clgl/clbg'
import ClbgEitder from './pages/clgl/clbg/clbgEitder'
import Clxs from './pages/clgl/clxs'
import Clwg from './pages/clgl/clwg'
import ClxsEitder from './pages/clgl/clxs/clxsEitder'
import ClxsTrEitder from './pages/clgl/clxs/clxsTrEitder'
import Cljf from './pages/clgl/cljf'
import Clph from './pages/clgl/clph'
import Cljfdjf from './pages/clgl/cljfdjf'
import Jfqr from './pages/jfqr'
import ClList from './pages/clgl/clList'
import ClxxOrLc from './pages/clgl/clxxOrLc'
import Ygkbksq from './pages/ygkyw/ygkbksq'
import Ygkgbfjf from './pages/ygkyw/ygkgbfjf'
import Ygkgs from './pages/ygkyw/ygkgs'
import Ygkjfjlcx from './pages/ygkyw/ygkjfjlcx'
import Ygkzxcz from './pages/ygkyw/ygkzxcz'
import Yktgbfjf from './pages/yktyw/yktgbfjf'
import Yktgs from './pages/yktyw/yktgs'
import Yktjfjlcx from './pages/yktyw/yktjfjlcx'
import Yktqxxxcx from './pages/yktyw/yktqxxxcx'
import Yktsfyz from './pages/yktyw/yktsfyz'
import Yktsq from './pages/yktyw/yktsq'
import Yktzxcz from './pages/yktyw/yktzxcz'
import Ygkjfqr from './pages/ygkyw/ygkjfqr'
import Ygkbkedit from './pages/ygkyw/ygkgbedit'
import Ygkgbshow from './pages/ygkyw/ygkgbshow'
import Ygkzhxxcx from './pages/ygkyw/ygkzhxxcx'
import Yktsqedit from './pages/yktyw/yktsqedit'
import Yktsqshow from './pages/yktyw/yktsqshow'
import Yktsfyzedit from './pages/yktyw/yktsfyzedit'
import Yktsfyzshow from './pages/yktyw/yktsfyzshow'
import Yktjfqr from './pages/yktyw/yktjfqr'
import Wyfjf from './pages/wysf/wyfjf'
import Ewmjf from './pages/wysf/ewmjf'
import Ewmjfqr from './pages/wysf/ewmjfqr'
import Wyfcx from './pages/wysf/wyfcx'
import Wyfkjf from './pages/wysf/wyfkjf'
import Wyyck from './pages/wysf/wyyck'
import Wyyckjfqr from './pages/wysf/wyyckjfqr'
import Yktbksq from './pages/yktyw/yktbksq'
import Yktgbshow from './pages/yktyw/yktgbshow'
import Yktgbedit from './pages/yktyw/yktgbedit'
import Yktgbfjfqr from './pages/yktyw/yktgbfjfqr'
import Wyfjfqr from './pages/wysf/wyfjfqr'
import Wyfcxmx from './pages/wysf/wyfcxmx'
import Wjmm from './pages/wjmm'
import Download from './pages/clgl/download'
import Czfbd from './pages/czfbd/index.js'
import fwxxbd from './pages/grzx/fwbd/index.js'
import fwxxbdlist from './pages/grzx/fwbd/fwbdlist.js'
import jfjl from './pages/grzx/jfjl/index.js'
import xggrxx from './pages/grzx/xxxg/index.js'
import sysm from './pages/grzx/sysm/index.js'
import Ttydbgbd from './pages/ttydbgbd/ttydbgbd'
import Tantumain from './pages/tantumain'
import crcssq from './pages/crcsyy/crcssq.js'
import crcsyz from './pages/crcsyy/crcsyz.js'
import cryz from './pages/crcsyy/cryz.js'

//新一卡通
import Newygkjyjl from './pages/newYgkyw/newygkjyjl'
import Newygkzhxx from './pages/newYgkyw/newygkzhxx'
import Newygkzxcz from './pages/newYgkyw/newygkzxcz'
import ScanQrCode from './pages/newYgkyw/scanQrCode'
import LossReport from './pages/newYgkyw/lossReport'
import ReplaceCard from './pages/newYgkyw/replaceCard'
import ReplaceCardSubmit from './pages/newYgkyw/replaceCardSubmit'
import ReplaceCardInfoShow from './pages/newYgkyw/replaceCardInfoShow'
import Postpone from './pages/newYgkyw/postpone'
import PostBackAplly from './pages/newYgkyw/postbackapply'
import PostponeApply from './pages/newYgkyw/postponeApply'
import PostponeShow from './pages/newYgkyw/postponeShow'
import  ApplyCard from './pages/newYgkyw/applycard/index.jsx'
import  ApplyCardShow from './pages/newYgkyw/applycardshow/index.jsx'
import  ApplyCardSubmit from './pages/newYgkyw/applycardsubmit/index.jsx'
import ApplyCardAudit from './pages/newYgkyw/applycardaudit/index.jsx'
import ApplyCardAuditShow from './pages/newYgkyw/applycardauditshow/index.jsx'

//预约申请
import YysqList from './pages/yysq/yysqlist'
import Yysq from   './pages/yysq/yysq/yysqUpdate.js'
import YysqView from   './pages/yysq/yysq/yysqView.js'

import YysqListQr from './pages/yysq/yysqlistqr'
import YysqViewQr from   './pages/yysq/yysqqr/yysqViewQr.js'
import YysqQr from './pages/yysq/yysqqr/yysqqr'
import OpenLittleApp from './pages/openlittleapp/index.jsx'
function App() {
  return (
    <Router>
      <div >
        <Route path="/openLittleApp" component={OpenLittleApp} />
        {/* 默认配置路由 */}
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="/ttydbgbd" component={Ttydbgbd} />
        <Route path="/tantumain" component={Tantumain} />
        <Route path="/wjmm" component={Wjmm} />
        <Route path="/login" component={Login} />
        <Route path="/zc" component={Zc} />
        <Route path="/main" component={Main} />
        <Route path="/clcrsq" component={Clcrsq} />
        <Route path="/clcrsqUpdate" component={ClcrsqUpdate} />
        <Route path="/clbg" component={Clbg} />
        <Route path="/clxs" component={Clxs} />
        <Route path="/clwg" component={Clwg} />
        <Route path="/clxsEitder" component={ClxsEitder} />
        <Route path="/clxsTrEitder" component={ClxsTrEitder} />
        <Route path="/clbgEitder" component={ClbgEitder} />
        <Route path="/cljf" component={Cljf} />
        <Route path="/clph" component={Clph} />
        <Route path="/cljfdjf" component={Cljfdjf} />
        <Route path="/jfqr" component={Jfqr} />
        <Route path="/clList" component={ClList} />
        <Route path="/clxxOrLc" component={ClxxOrLc} />
        <Route path="/download" component={Download} />
        <Route path="/crcssq" component={crcssq} />
        <Route path="/crcsyz" component={crcsyz} />
        <Route path="/cryz" component={cryz} />
        {/* 新一卡通 */}
        <Route path="/newygkjyjl" component={Newygkjyjl} />
        <Route path="/newygkzhxx" component={Newygkzhxx} />
        <Route path="/newygkzxcz" component={Newygkzxcz} />
        <Route path="/scanQrCode" component={ScanQrCode} />
        <Route path="/lossReport" component={LossReport} />
        <Route path="/replaceCard" component={ReplaceCard} />
        <Route path="/replaceCardInfoShow" component={ReplaceCardInfoShow} />
        <Route path="/replaceCardSubmit" component={ReplaceCardSubmit} />
        <Route path="/postpone" component={Postpone} />
        <Route path="/postponeApply" component={PostponeApply} />
        <Route path="/postponeShow" component={PostponeShow} />
        <Route path="/postbackapply" component={PostBackAplly} />
        <Route path="/applyCard" component={ApplyCard} />
        <Route path="/applyCardShow" component={ApplyCardShow} />
        <Route path="/applyCardSubmit" component={ApplyCardSubmit} />
        <Route path="/applyCardAudit" component={ApplyCardAudit} />
        <Route path="/applyCardAuditShow" component={ApplyCardAuditShow} />
        
        {/* 员工卡 */}
        <Route path="/ygkbksq" component={Ygkbksq} />
        <Route path="/ygkgbfjf" component={Ygkgbfjf} />
        <Route path="/ygkgs" component={Ygkgs} />
        <Route path="/ygkjfjlcx" component={Ygkjfjlcx} />
        <Route path="/ygkzxcz" component={Ygkzxcz} />
        <Route path="/ygkjfqr" component={Ygkjfqr} />
        <Route path="/ygkgbedit" component={Ygkbkedit} />
        <Route path="/ygkgbshow" component={Ygkgbshow} />
        <Route path="/ygkzhxxcx" component={Ygkzhxxcx} />


        {/* 一卡通 */}
        <Route path="/yktgbfjf" component={Yktgbfjf} />
        <Route path="/yktgs" component={Yktgs} />
        <Route path="/yktjfjlcx" component={Yktjfjlcx} />
        <Route path="/yktqxxxcx" component={Yktqxxxcx} />
        <Route path="/yktsfyz" component={Yktsfyz} />
        <Route path="/yktsq" component={Yktsq} />
        <Route path="/yktzxcz" component={Yktzxcz} />
        <Route path="/yktjfqr" component={Yktjfqr} />
        <Route path="/yktbksq" component={Yktbksq} />
        <Route path="/yktgbshow" component={Yktgbshow} />
        <Route path="/yktgbedit" component={Yktgbedit} />
        <Route path="/yktsfyzedit" component={Yktsfyzedit} />
        <Route path="/yktsfyzshow" component={Yktsfyzshow} />
        <Route path="/yktsqedit" component={Yktsqedit} />
        <Route path="/yktsqshow" component={Yktsqshow} />

        {/* 物业 */}
        <Route path="/wyfjf" component={Wyfjf} />
        <Route path="/wyfcx" component={Wyfcx} />
        <Route path="/wyfkjf" component={Wyfkjf} />
        <Route path="/wyyck" component={Wyyck} />
        <Route path="/wyyckjfqr" component={Wyyckjfqr} />
        <Route path="/ewmjf" component={Ewmjf} />
        <Route path="/ewmjfqr" component={Ewmjfqr} />
        <Route path="/yktgbfjfqr" component={Yktgbfjfqr} />
        <Route path="/wyfjfqr" component={Wyfjfqr} />
        <Route path="/wyfcxmx" component={Wyfcxmx} />
        
         {/* 预约申请 */}
        <Route path="/YysqList" component={YysqList} />
        <Route path="/Yysq" component={Yysq} />
        <Route path="/YysqView" component={YysqView} />

         {/* 预约确认 */}
         <Route path="/YysqListQr" component={YysqListQr} />
         <Route path="/YysqViewQr" component={YysqViewQr} />
         <Route path="/YysqQr" component={YysqQr} />
     
        {/* 出租房表单 */}
        <Route path="/czfbd" component={Czfbd} />

        {/* 个人主页 */}
        <Route path="/jfjl" component={jfjl} />
        <Route path="/xggexx" component={xggrxx} />
        <Route path="/fwxxbd" component={fwxxbd} />
        <Route path="/fwxxbdlist" component={fwxxbdlist} />
        <Route path="/sysm" component={sysm} />
      </div>


    </Router>
  );
}

export default App;
